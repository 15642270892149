<template>
    <div>
        <el-row :gutter="20">
            <el-col :md="isDetailPath ? 4 : 5">
                <el-select v-model="filter.tahun" multiple filterable :multiple-limit="isDetailPath ? 0 : 1" placeholder="Tahun">
                    <el-option v-for="item in lov.tahun" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col v-if="isDetailPath" :md="4">
                <el-select v-model="filter.kecamatan" multiple filterable placeholder="Kecamatan"
                    style="margin-bottom: 10px;">
                    <el-option v-for="item in lov.kecamatan" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col :md="isDetailPath ? 4 : 5">
                <el-select :clearable="true" v-model="filter.fungsi" multiple filterable placeholder="Fungsi">
                    <el-option v-for="item in lov.fungsi" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col :md="isDetailPath ? 4 : 5">
                <el-select :clearable="true" v-model="filter.kepemilikan" multiple filterable placeholder="Kepemilikan">
                    <el-option v-for="item in lov.kepemilikan" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col :md="isDetailPath ? 4 : 5">
                <el-select :clearable="true" v-model="filter.potensi" filterable placeholder="Potensi">
                    <el-option v-for="item in lov.potensi" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col v-if="isDetailPath" :md="4">
                <el-select :clearable="true" v-model="filter.status_permohonan" multiple filterable
                    placeholder="Status">
                    <el-option v-for="item in lov.status_permohonan" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col v-if="isDetailPath" :md="4">
                <el-select :clearable="true" v-model="filter.temuan" filterable placeholder="Temuan">
                    <el-option v-for="item in lov.temuan" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col v-if="isDetailPath" :md="4">
                <el-select :clearable="true" v-model="filter.pembayaran" filterable placeholder="Pembayaran">
                    <el-option v-for="item in lov.pembayaran" :key="item.lov_value" :label="item.lov_label"
                        :value="item.lov_value" />
                </el-select>
            </el-col>
            <el-col v-if="isDetailPath" :md="3">
                <el-input placeholder="Pencarian..." v-model="filter.search_input" class="input-with-select">
                </el-input>
            </el-col>
            <el-col :md="4">
                <el-button @click="cariData">Cari</el-button>
            </el-col>
        </el-row>
    </div>
</template>




<script>
import axios from 'axios'
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';


export default defineComponent({
    name: 'FilterBoard',

    data() {
        return {
            lov: {
                fungsi: [],
                kepemilikan: [],
                potensi: [],
                temuan: '',
                status_permohonan: [],
                tahun: [],
                kecamatan: [],
                pembayaran: ''
            },
            filter: {}
        }
    },
    computed: {
        isDetailPath() {
            return this.$route.name === 'berkas' || this.$route.name === 'unduh';
        },
    },
    created() {
        this.fetchDataLOV();
        if (this.$route.name === 'berkas') {
            this.filter = {
                tahun: (this.$store.state.filter.tahun ? this.$store.state.filter.tahun : ['2024']),
                fungsi: (this.$store.state.filter.fungsi ? this.$store.state.filter.fungsi : []),
                kepemilikan: (this.$store.state.filter.kepemilikan ? this.$store.state.filter.kepemilikan : []),
                potensi: (this.$store.state.filter.potensi ? this.$store.state.filter.potensi : ''),
                kecamatan: (this.$store.state.filter.kecamatan ? this.$store.state.filter.kecamatan : []),
                status_permohonan: (this.$store.state.filter.status_permohonan ? this.$store.state.filter.status_permohonan : []),
                temuan: (this.$store.state.filter.temuan ? this.$store.state.filter.temuan : ''),
                pembayaran: (this.$store.state.filter.pembayaran ? this.$store.state.filter.pembayaran : ''),
            }
            
            this.setFilter(this.filter)
        } else if (this.$route.name === 'dashboard' || this.$route.name ==='home') {
            this.filter = {
                tahun: ['2024'],
                fungsi: (this.$store.state.filter.fungsi ? this.$store.state.filter.fungsi : []),
                kepemilikan: (this.$store.state.filter.kepemilikan ? this.$store.state.filter.kepemilikan : []),
                potensi: (this.$store.state.filter.potensi ? this.$store.state.filter.potensi : '')
            }
            
            this.setFilter(this.filter)
        }
        // console.log('change page',this.filter)
        // this.fetchDataLOV({ lov_id: "fungsi" });
        // this.fetchDataLOV({ lov_id: "kepemilikan" });
        // this.fetchDataLOV({ lov_id: "potensi" });
        // this.fetchDataLOV({ lov_id: "temuan" });
        // this.fetchDataLOV({ lov_id: "tahun" });
        // this.fetchDataLOV({ lov_id: "kecamatan" });
        // this.fetchDataLOV({ lov_id: "status_permohonan" });
        // this.fetchDataLOV({ lov_id: "tagihan" });

    },
    methods: {
        ...mapMutations(['setFilter']),
        cariData() {
            if (this.$route.name != 'unduh') {
                this.setFilter(this.filter)
            }
            this.$emit('filter-data', this.filter);
        },
        fetchDataLOV() {
            try {
                axios.get(this.$store.state.base_url + "/api/lov?limit=2000",
                    {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.auth_token}`
                        }
                    }).then(response => {
                        const sortedData = response.data.data.sort((a, b) => a.lov_order - b.lov_order);
                        sortedData.forEach(item => {
                            if (!this.lov[item.lov_id]) {
                                this.lov[item.lov_id] = [];
                            }
                            this.lov[item.lov_id].push(item)
                        })
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })


            } catch (error) {
                console.error('Terjadi kesalahan saat mengambil data:', error);
            }
        },
    }
})
</script>
