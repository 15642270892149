<template>
    <div class="download-page">
        <el-row :gutter="20">
            <el-col :sm="{ span: 20, offset: 2 }" :xs="{ span: 20, offset: 2 }">
                <div class="download-form">
                    <h1>Unduh data</h1>
                    <br />
                    <div>
                        <el-form :label-position="'top'" :model="form" status-icon :rules="rules" ref="ruleForm"
                            label-width="120px" class="demo-ruleForm">
                            <el-form-item label="Judul" prop="title">
                                <el-input v-model="form.title"></el-input>
                            </el-form-item>
                            <el-form-item label="Kolom yang Ingin Diunduh" prop="columns">
                                <el-select v-model="form.columns" multiple filterable placeholder="Kolom">
                                    <el-option v-for="item in columns" :key="item.lov_value" :label="item.lov_label"
                                        :value="item.lov_value" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Filter">
                                <FilterBoard @filter-data="filterData"></FilterBoard><br>
                            </el-form-item>
                            <br />
                            <p>Jumlah berkas ditemukan: {{ totalBerkas }}</p>

                            <br />
                            <el-form-item>
                                <el-button type="primary" @click="download('ruleForm')">
                                    <!-- <vue-excel-xlsx
                                    :data="tableData"
                                    :columns="form.columns"
                                    :file-name="form.title ? form.title.replace(/\s+/g, '') : 'DataPBG'"
                                    :file-type="'xlsx'"
                                    :sheet-name="'Data PBG'"
                                    > -->
                                    Download
                                    <!-- </vue-excel-xlsx> -->
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import FilterBoard from '@/components/FilterBoard.vue'

export default {
    name: "UnduhPage",
    components: {
        FilterBoard
    },
    data() {
        return {
            tableData: [],
            tableCount: 0,
            fields: [],
            form: {
                title: 'RekapPBG',
                columns: ["tgl_permohonan","nama_pemilik","nama_bangunan","status_permohonan"],
            },
            rules: {
                title: [
                    { required: true, message: 'Namakan dokumen unduhan', trigger: 'blur' }
                ],
                columns: [
                    { required: true, message: 'Pilih setidaknya satu kolom', trigger: 'change' }
                ]
            },
            columns: []
        }
    },
    created() {
        this.fetchColumns()
    },
    computed: {
        totalBerkas() {
            return this.tableCount;
        }
    },
    methods: {
        filterData(val) {
            this.filter = val
            this.fetchData()
        },
        fetchColumns() {
            try {
                const params = {
                    lov_id: 'kolom_data'
                }
                axios.get(this.$store.state.base_url + "/api/lov",
                    {
                        params,
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.auth_token}`
                        }
                    }).then(response => {
                        const sortedData = response.data.data.sort((a, b) => a.lov_order - b.lov_order);
                        this.columns = sortedData;
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })


            } catch (error) {
                console.error('Terjadi kesalahan saat mengambil data:', error);
            }
        },
        download(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.exportCSV()
                } else {
                    return false
                }
            })
        },
        fetchData() {
            try {
                const params = {
                    limit: -1,
                    offset: -1,
                    tahun_permohonan: this.filter.tahun.join(','),
                    kecamatan: this.filter.kecamatan.join(','),
                    kepemilikan: this.filter.kepemilikan.join(','),
                    fungsi_bg: this.filter.fungsi.join(','),
                    potensi: this.filter.potensi,
                    status_permohonan: this.filter.status_permohonan.join(','),
                    temuan: this.filter.temuan,
                    query: this.filter.search_input,
                    pembayaran: this.filter.pembayaran,
                    fields: this.form.columns.length > 0 ? this.form.columns.join(',') : 'no_registrasi'
                }
                axios.get(this.$store.state.base_url + "/api/pbg",
                    {
                        params,
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.auth_token}`
                        }
                    }).then(response => {
                        this.tableData = response.data.data;
                        this.tableCount = response.data.count;
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })


            } catch (error) {
                console.error('Terjadi kesalahan saat mengambil data:', error);
            }
        },
        exportCSV() {
            const rows = [
            ];
            this.tableData.forEach(function(rec, index) {
                rows[index]=[]
                for (let key in rec) {
                    rows[index].push(rec[key])
                }
            });

            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", this.form.title+".csv");
            document.body.appendChild(link);

            link.click();
        }

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.download-form {
    border-color: #030f6b;
    border-width: 2px;
    border-style: solid;
    border-radius: 15px;
    padding: 36px;
}

.download-page {
    min-height: 100vh;

}

.logo {
    flex: 1;
    text-align: center;
}

.el-button {
    --el-button-hover-bg-color: #e3effb;
}

.el-checkbox {
    --el-color-primary: #030f6b
}

.el-link {
    --el-link-hover-text-color: #030f6b;
}
</style>