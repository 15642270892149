<template>
    <Bar :data="data" :options="options" />
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    name: 'BarKinerjaBulanan',
    components: {
        Bar
    },
    data() {
        return {
            data: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [
                    {
                        label: 'Berkas Diajukan',
                        backgroundColor: '#f56c6c',
                        data: [40, 20, 12, 39, 10, 40, 39, 20, 40, 20, 12, 11]
                    },
                    {
                        label: 'Berkas Diverifikasi',
                        backgroundColor: '#e6a23c',
                        data: [40, 30, 62, 19, 70, 30, 29, 50, 30, 60, 42, 51]
                    },
                    {
                        label: 'Berkas Dicetak',
                        backgroundColor: '#67c23a',
                        data: [20, 50, 26, 38, 10, 40, 39, 30, 40, 20, 46, 38]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true
            }

        }

    }
}
</script>
