<template>
  <div class="home">
    <KinerjaPage msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import KinerjaPage from '@/components/KinerjaPage.vue'


export default {
  name: 'KinerjaView',
  components: {
    KinerjaPage
  }
}
</script>
