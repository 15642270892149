<template>
    <div class="loginpage">
      <LoginPage msg="Welcome to Your Vue.js App"/>
    </div>
  </template>
  
  <script>
  import LoginPage from '@/components/LoginPage.vue'
  
  
  export default {
    name: 'LoginView',
    components: {
      LoginPage,
    }
  }
  </script>
  