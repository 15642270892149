<template>
    <div class="loginpage">
        <div class="logo">
            <img style="width: 150px" src="@/assets/logo.png" alt="Element logo" />
        </div>
        <br />
        <el-row :gutter="20">
            <el-col :sm="{ span: 12, offset: 6 }" :xs="{ span: 20, offset: 2 }">
                <div class="loginform">
                    <p>Masuk ke akun Sibedas DPUTR-mu</p>
                    <br />
                    <el-form :label-position="'top'" :model="form" status-icon :rules="rules" ref="ruleForm"
                        label-width="120px" class="demo-ruleForm">
                        <el-form-item label="Email" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Password" prop="password">
                            <el-input type='password' v-model="form.password" autocomplete="off" show-password></el-input>
                        </el-form-item>
                        <el-alert v-show="response.success!='pending'" :title="response.msg" :type="response.success" />
                        <br />
                        <br />
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">Masuk</el-button>
                        </el-form-item>
                    </el-form>
                    <br />

                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "LoginPage",
    data() {
        return {
            activeIndex: "/",
            form: {
                name: '',
                password: '',
            },
            response: {
                msg: '',
                success: 'pending'
            },
            rules: {
                name: [
                    { required: true, message: 'Please input your name', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Please input your password', trigger: 'blur' }
                ]
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.response.success='pending'
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const postData = {
                        email: this.form.name,
                        password: this.form.password
                    };
                    axios.post(this.$store.state.base_url + "/api/login", postData).then(response => {
                         
                        if (response.data.success) {
                            this.response.success='success'
                            this.response.msg = response.data.message
                            this.$store.state.auth_token = response.data.token
                            localStorage.auth_token = response.data.token
                            localStorage.username = response.data.user.name
                            localStorage.useremail = response.data.user.email
                            localStorage.userid = response.data.user.id
                            this.$router.push('/')
                        }else{
                            this.response.success='error'
                            this.response.msg = response.data.message
                        }
                    })
                        .catch(error => {
                            this.response.success='error'
                            this.response.msg = "Nama atau password salah. Silahkan coba lagi."
                            console.error('There was an error!', error);
                        })

                } else {
                    return false;
                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginform {
    border-color: #030f6b;
    border-width: 2px;
    border-style: solid;
    border-radius: 15px;
    padding: 36px;
}

.loginpage {
    min-height: 100vh;
}

.logo {
    flex: 1;
    text-align: center;
}

.el-button {
    --el-button-hover-bg-color: #e3effb;
}

.el-checkbox {
    --el-color-primary: #030f6b
}

.el-link {
    --el-link-hover-text-color: #030f6b;
}

span {
    font-size: small;
    color: #FF6F6F;
}
</style>