import { createRouter, createWebHashHistory } from 'vue-router'
//import store from '../store';

import HomeView from '../views/HomeView.vue'
import BerkasView from '../views/BerkasView.vue'
import DetailView from '../views/DetailView.vue'
import KinerjaView from '../views/KinerjaView.vue'
import LoginView from '../views/LoginView.vue'
import UnduhView from '../views/UnduhView.vue'
import PasswordView from '../views/PasswordView.vue'
import PublicDetailView from '../views/PublicDetailView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomeView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/berkas',
    name: 'berkas',
    component:  BerkasView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/kinerja',
    name: 'kinerja',
    component:  KinerjaView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component:  DetailView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/masuk',
    name: 'masuk',
    component:  LoginView,
    meta: { showMainMenu: false, requiresAuth: false },
  },
  {
    path: '/unduh',
    name: 'unduh',
    component:  UnduhView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/password',
    name: 'password',
    component:  PasswordView,
    meta: { showMainMenu: true, requiresAuth: true },
  },
  {
    path: '/lacak/:no_registrasi',
    name: 'tracking',
    component:  PublicDetailView,
    meta: { showMainMenu: false, requiresAuth: false },
  },
  
  {
    path: '/lacak',
    name: 'trackingAwal',
    component:  PublicDetailView,
    meta: { showMainMenu: false, requiresAuth: false },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) { // Periksa jika route memerlukan otentikasi
    if (localStorage.auth_token) { // Pastikan ada token otentikasi
    //if (store.state.auth_token) { // Pastikan ada token otentikasi
      next(); // Lanjutkan navigasi ke route yang diinginkan
    } else {
      next('/masuk'); // Redirect ke halaman login jika tidak ada token
    }
  } else {
    next(); // Lanjutkan ke route jika tidak memerlukan otentikasi
  }
});

export default router
