<template>
    <div>
        <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false" :router="true" background-color="#030F6B"
            text-color="#FBFDFF">

            <el-menu-item class="hidden-md-and-down" index="/">
                <img style="width: 50px" src="@/assets/logo.png" alt="Element logo" />
            </el-menu-item>
            <div class="flex-grow" />
            <el-menu-item index="/dashboard">Dashboard</el-menu-item>
            <el-menu-item index="/berkas">Berkas</el-menu-item>
            <el-menu-item index="/unduh">Unduh</el-menu-item>

            <el-sub-menu>
                <template #title>Akun</template>
                <el-menu-item  index="/password">Ganti Sandi</el-menu-item>
                <el-menu-item  @click="keluar">Keluar</el-menu-item>
            </el-sub-menu>

        </el-menu>
    </div>
</template>



<script>
import 'element-plus/theme-chalk/display.css'
import { defineComponent } from 'vue';
import { ElMessageBox } from 'element-plus'

export default defineComponent({
    name: 'MainMenu',
    data() {
        return {
            activeIndex: "/",
        }
    },
    methods: {
        keluar() {
            ElMessageBox.confirm(
                'Apakah anda yakin akan Keluar ?',
                'Konfirmasi',
                {
                    confirmButtonText: 'Keluar',
                    cancelButtonText: 'Batal',
                    type: 'warning',
                }
            )
                .then(() => {

                    localStorage.removeItem('auth_token');
                    this.$router.push('/masuk')
                })
                .catch(() => {
                })
        }
    }

})
</script>
