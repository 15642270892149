<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col :md="14">
        <FilterBoard @filter-data="filterData"></FilterBoard><br>
      </el-col>
      <el-col class="total-berkas" :md="3">
        {{sync_data.updated_at}}
      </el-col>
      <el-col class="total-berkas" :md="3">
        Total Berkas: {{ total.total_berkas }}
      </el-col>
      <el-col :md="4">
        <div class="switch">
          <el-switch v-model="detailvalue" active-color="#030F6B">
          </el-switch>
          <p class="switch-status" v-if="!detailvalue">Detil disembunyikan</p>
          <p class="switch-status" v-if="detailvalue">Detil ditunjukkan</p>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="11">
        <el-card shadow="never" style="min-height: 250px;">
          <h1>SIBEDAS PBG</h1>
          <el-card class="pad-card" shadow="never">
            <el-tooltip effect="dark" content="Jumlah target nilai retribusi tahunan yang ditentukan."
              placement="bottom">
              <div class="statistic">
                <div class="statistic-title">Target PAD</div>
                <div class="statistic-value">{{ formatRupiah(total.target) }}</div>
              </div>
            </el-tooltip>
          </el-card>
          
          <el-card @click="potensiBerkas(false)" class="pot-card" shadow="never">
            <el-tooltip effect="dark" content="Jumlah nilai retribusi permohonan yang dapat diproses (memiliki dokumen lengkap) tahun ini dan tahun-tahun lalu yang belum selesai." placement="bottom">
              <div class="statistic">
                <div class="statistic-title">Total Potensi</div>
                <div class="statistic-value">{{ formatRupiah(total.potensi) }}</div>
              </div>
            </el-tooltip>
            <div class="proyek-bar">
              <el-progress :text-inside="true" striped status="warning" :color="'#FFCD6B'" :stroke-width="20"
                :percentage="percentage_potensi"></el-progress>
            </div>
            <el-row v-if="detailvalue" class="detail" :gutter="2">
              <el-col :md="7">Jumlah berkas: {{ total.total_berkas_potensi || '0' }}</el-col>
              <el-col :md="17">Persentase dalam rupiah: {{ percentage_potensi }}%</el-col>
            </el-row>
          </el-card>

          <el-card @click="terbitBerkas(false)" class="terbit-card" shadow="never">
            <el-tooltip effect="dark" content="Jumlah nilai retribusi permohonan yang diselesaikan tahun ini."
              placement="bottom">
              <div class="statistic">
                <div class="statistic-title">Total Realisasi</div>
                <div class="statistic-value">{{ formatRupiah(total.realisasi) }}</div>
              </div>
            </el-tooltip>
            <div class="proyek-bar">
              <el-progress :text-inside="true"  :color="'#359439'" striped :stroke-width="20"
                :percentage="percentage_terbit_potensi">
              </el-progress>
            </div>
            <el-row v-if="detailvalue" class="detail" :gutter="2">
              <el-col :md="8">Jumlah berkas: {{ total.total_berkas_terbit || '0' }}</el-col>
              <el-col :md="8">{{ percentage_terbit_target }}% dari Target</el-col>
              <el-col :md="8">{{ percentage_terbit_potensi }}% dari Total Potensi</el-col>
            </el-row>
          </el-card>
          
          <el-card v-if="detailvalue" @click="allBerkas()" class="per-card" shadow="never">
            <el-tooltip effect="dark" content="Jumlah nilai retribusi seluruh permohonan masuk tahun ini dan tahun-tahun lalu yang belum selesai." placement="bottom">
              <div class="statistic">
                <div class="statistic-title">Total Permohonan Masuk</div>
                <div class="statistic-value">{{ formatRupiah(total.permohonan) }}</div>
              </div>
            </el-tooltip>
            <div class="proyek-bar">
              <el-progress :text-inside="true" striped status="warning" :color="'#565656'" :stroke-width="20"
                :percentage="percentage_permohonan"></el-progress>
            </div>
            <el-row v-if="detailvalue" class="detail" :gutter="2">
              <el-col :md="7">Jumlah berkas: {{ total.total_berkas || '0' }}</el-col>
              <el-col :md="17">Persentase dalam rupiah: {{ percentage_permohonan }}%</el-col>
            </el-row>
          </el-card>
          <el-card class="dev-card" shadow="never">
            <el-tooltip effect="dark" content="Deviasi Target terhadap Potensi." placement="bottom">
              <div class="statistic">
                <div class="statistic-title">Total Deviasi</div>
                <div class="statistic-value">{{ formatRupiah(total.target - total.potensi) }}</div>
              </div>
            </el-tooltip>
            <div class="proyek-bar">
              <el-tooltip effect="dark" :content="'Deviasi Target terhadap Potensi.'" placement="bottom">
                <el-progress :text-inside="true" striped status="exception" :color="'#FF6F6F'" :stroke-width="20"
                  :percentage="percentage_deviasi"></el-progress>
              </el-tooltip>
            </div>
          </el-card>

        </el-card>
      </el-col>

      <el-col :md="13">
        <el-card shadow="never" style="min-height: 250px;">
          <h1>Pemetaan Berkas</h1>
          <div @click="filteredBerkas(item.status_permohonan)" class="progress" :gutter="2"
            v-for="(item, index) in berkas_by_status" :key="index">
            <el-row v-if="(item.status_permohonan !== 'Berkas Dianulir' && item.status_permohonan !=='Dokumen Belum Lengkap') || detailvalue">
              <el-col :md="16">
                <el-tooltip effect="dark"
                  :content="item.label + ' : Berkas ' + item.jumlah_berkas + ' | Nilai Retribusi ' + formatRupiah(parseInt(item.nilai_retribusi_keseluruhan_simbg) + parseInt(item.usulan_retribusi)) + '.'"
                  placement="bottom">
                  <el-progress :text-inside="true" :show-text="detailvalue ? false: true" :percentage="persen_berkas(item)" :color="opt_colors[item.order]"
                    :stroke-width="detailvalue ? 15 : 30"></el-progress>
                </el-tooltip>
              </el-col>
              <el-col :md="8">
                <p style="margin-top: 5px">
                  <span v-if="item.status_permohonan == '' || item.status_permohonan == null">Status tidak diisi</span>
                  {{ item.label }}
                </p>
              </el-col>
            </el-row>
            <el-row class="detail" v-if="detailvalue">
              <el-col :md="8">Persentase dalam rupiah: {{ persen_berkas(item) }}%</el-col>
              <el-col :md="9">Nilai retribusi: {{ formatRupiah(parseInt(item.nilai_retribusi_keseluruhan_simbg) + parseInt(item.usulan_retribusi)) }}</el-col>
              <el-col :md="7">Jumlah berkas: {{ item.jumlah_berkas || '0' }}</el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <br>

    <el-row :gutter="20">
      <el-col :md="8">
        <el-card class="pie-view" shadow="never" style="min-height: 250px;">
          <el-tooltip effect="dark" content="Semua Berkas permohonan yang menjadi temuan." placement="bottom">
            <h1>Total Potensi Temuan</h1>
          </el-tooltip>
          <el-row :gutter="12">
            <el-col @click="potensiBerkas(true)" :xs="12" :md="8">
              <Pie :data="dataPieTemuan" :options="pieOptions" />
            </el-col>
            <el-col :xs="12" :md="14">
              <br>
              <StatsBox
                :statValue="formatRupiah(temuan.potensi.nilai_retribusi_keseluruhan_simbg + temuan.potensi.usulan_retribusi)"
                :statDesc="Math.round(temuan.potensi.nilai_retribusi_keseluruhan_simbg + temuan.potensi.usulan_retribusi / total.potensi * 100) + '% berkas potensi merupakan hasil temuan lapangan.'"
                :statFooter="temuan.potensi.jumlah_berkas + ' total berkas.'" />
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :md="8">
        <el-card class="pie-view" shadow="never" style="min-height: 250px;">
          <el-tooltip effect="dark" content="Berkas permohonan yang sudah terbit dan menjadi temuan."
            placement="bottom">
            <h1>Total Realisasi Temuan</h1>
          </el-tooltip>
          <el-row :gutter="20">
            <el-col @click="terbitBerkas(true)" :xs="12" :md="8">
              <Pie :data="dataPieRealisasi" :options="pieOptions" />
            </el-col>
            <el-col :xs="12" :md="14">
              <br>
              <StatsBox :statValue="formatRupiah(temuan.terealisasi.nilai_retribusi_keseluruhan_pad)"
                :statDesc="Math.round(temuan.terealisasi.nilai_retribusi_keseluruhan_pad / total.realisasi * 100) + '% berkas terealisasi merupakan hasil temuan lapangan.'"
                :statFooter="temuan.terealisasi.jumlah_berkas + ' total berkas.'" />
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :md="8">
        <el-card class="pie-view" @click="tagihanBerkas" shadow="never" style="min-height: 250px;">
          <el-tooltip effect="dark" content="Semua berkas permohonan yang masih terhutang." placement="bottom">
            <h1>Menunggu Pelunasan</h1>
          </el-tooltip>
          <el-row :gutter="12">
            <el-col :xs="12" :md="8">
              <Pie :data="dataPieTagihan" :options="pieOptions" />
            </el-col>
            <el-col :xs="12" :md="14">
              <br>
              <StatsBox :statValue="formatRupiah(tagihan.total_tagihan)"
                :statDesc="Math.round(tagihan.berkas_tagihan / tagihan.total_berkas * 100) + '% berkas menunggu pelunasan retribusi.'"
                :statFooter="tagihan.berkas_tagihan + ' total berkas.'" />
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import axios from 'axios'
import FilterBoard from '@/components/FilterBoard.vue'
import { Pie } from 'vue-chartjs'
import StatsBox from "@/components/StatsBox.vue";


export default {
  name: 'HomeView',
  components: {
    Pie,
    FilterBoard,
    StatsBox,
  },
  data() {
    return {
      date: new Date(2024, 6, 19),
      opt_colors: ['#565656','#9BB0F3', '#8CCAEE', '#030F6B', '#FFCD6B', '#97E7C0', '#359439', '#FF6F6F'],
      berkas_by_status: {
        'Dokumen Belum Lengkap': { status_permohonan: 'Dokumen Belum Lengkap', label: 'Dokumen Belum Lengkap', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 0 },
        'Verifikasi Kelengkapan Dokumen': { status_permohonan: 'Verifikasi Kelengkapan Dokumen', label: 'Verifikasi Kelengkapan Dokumen', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 1 },
        'BA TPA/TPT': { status_permohonan: 'BA TPA/TPT', label: 'BA TPA/TPT', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 2 },
        'Perhitungan Retribusi': { status_permohonan: 'Perhitungan Retribusi', label: 'Perhitungan Retribusi', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 3 },
        'Validasi PTSP': { status_permohonan: 'Validasi PTSP', label: 'Validasi PTSP', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 4 },
        'Penagihan Retribusi': { status_permohonan: 'Penagihan Retribusi', label: 'Penagihan Retribusi', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 5 },
        'Pelunasan Retribusi': { status_permohonan: 'Pelunasan Retribusi', label: 'Terealisasi', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 6 },
        'Berkas Dianulir': { status_permohonan: 'Berkas Dianulir', label: 'Berkas Dianulir', jumlah_berkas: 0, usulan_retribusi: 0, nilai_retribusi_keseluruhan_simbg: 0, nilai_retribusi_keseluruhan_pad: 0, order: 7 },
      },
      temuan: {
        total_berkas: 0,
        total_nilai: 0,
        potensi: {
          jumlah_berkas: 0,
          nilai_retribusi_keseluruhan_simbg: 0, usulan_retribusi: 0
        },
        terealisasi: {
          jumlah_berkas: 0,
          nilai_retribusi_keseluruhan_pad: 0
        }
      },
      tagihan: {
        total_berkas: 0,
        total_tagihan: 0,
        berkas_tagihan: 0
      },
      total: {
        target: 0,
        total_potensi: 0,
        total_berkas_potensi: 0,
        total_terbit: 0,
        total_berkas_terbit: 0,
        total_berkas: 0
      },
      dataPieTemuan: {
        labels: ['Lainya', 'Temuan'],
        datasets: [
          {
            backgroundColor: ['#ebeef5', '#FFCD6B'],
            data: [0, 100]
          }
        ]
      },
      dataPieRealisasi: {
        labels: ['Lainya', 'Temuan'],
        datasets: [
          {
            backgroundColor: ['#ebeef5', '#FFCD6B'],
            data: [0, 100]
          }
        ]
      },
      dataPieTagihan: {
        labels: ['Lainya', 'Tagihan'],
        datasets: [
          {
            backgroundColor: ['#ebeef5', '#FFCD6B'],
            data: [0, 100]
          }
        ]
      },
      pieOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false, // Hide legend
          },
        },
      },
      detailvalue: false,
      sync_data:{}
    }
  },
  computed: {
    percentage_terbit_target() {
      if (this.total.target == 0) { return 0 }
      return Math.round(this.total.total_terbit / this.total.target * 100)
    },
    percentage_terbit_potensi() {
      if (this.total.target == 0) { return 0 }
      return Math.round(this.total.total_terbit / this.total.total_potensi * 100)
    },
    percentage_potensi() {
      if (this.total.target == 0) { return 0 }
      return Math.round(this.total.total_potensi / this.total.target * 100)
    },
    percentage_permohonan() {
      if (this.total.target == 0) { return 0 }
      return Math.round(this.total.total_permohonan / this.total.target * 100)
    },
    percentage_deviasi() {
      if (this.total.target == 0) { return 0 }
      return Math.round((this.total.target - this.total.total_potensi) / this.total.target * 100)
    },
    formattedDate() {
      const day = String(this.date.getDate()).padStart(2, '0');
      const month = String(this.date.getMonth() +1).padStart(2, '0'); // Months are 0-based
      const year = this.date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    formatRupiah(value) {
      if (!value) return 'Rp 0';
      return '' + parseInt(value, 10).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).replace(/,00$/, '');
    },
    filterData(val) {
      this.filter = val
      this.fetchData()
    },
    fetchData() {
      try {
        const params = {
          kepemilikan: this.$store.state.filter.kepemilikan ? this.$store.state.filter.kepemilikan.join(',') : '',
          fungsi_bg: this.$store.state.filter.fungsi ? this.$store.state.filter.fungsi.join(',') : '',
          potensi: this.$store.state.filter.potensi ? this.$store.state.filter.potensi : '',
          tahun:this.$store.state.filter.tahun.join(',')
        }
        axios.get(this.$store.state.base_url + "/api/pbg/statistik/"+this.$store.state.filter.tahun[0],
          {
            params,
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            this.sync_data = response.data.sync_data
            this.total = response.data.tahunan
            this.total.total_berkas = response.data.total_berkas
            this.total.total_terbit = response.data.terbit.total_terbit
            this.total.total_berkas_terbit = response.data.terbit.total_berkas
            this.total.total_potensi = response.data.potensi.total_potensi
            this.total.total_permohonan = response.data.permohonan.total_permohonan
            this.total.total_berkas_potensi = response.data.potensi.total_berkas
            response.data.status_permohonan.forEach(item => {
              const status = item.status_permohonan || 'Unknown'; // Default to 'Unknown' if status is null

              if (status in this.berkas_by_status) {
                this.berkas_by_status[status].jumlah_berkas = item.jumlah_berkas
                this.berkas_by_status[status].usulan_retribusi = item.usulan_retribusi ?? 0
                this.berkas_by_status[status].nilai_retribusi_keseluruhan_simbg = item.nilai_retribusi_keseluruhan_simbg ?? 0
                this.berkas_by_status[status].nilai_retribusi_keseluruhan_pad = item.nilai_retribusi_keseluruhan_pad ?? 0
                this.berkas_by_status[status].denda = item.denda ?? 0
              }
            });
            if (this.$store.state.filter.tahun[0] == "2024") {
              this.total.potensi = response.data.potensi.total_potensi
              this.total.permohonan = response.data.permohonan.total_permohonan
              this.total.realisasi = response.data.terbit.total_terbit
            }

            response.data.temuan.map(val => {
              if (val.selesai_terbit == "") {
                this.temuan.potensi = val
              }
              if (val.selesai_terbit == "Selesai Terbit") {
                this.temuan.terealisasi = val
              }
            })
            this.dataPieRealisasi = {
              labels: ['Lainya', 'Temuan'],
              datasets: [
                {
                  backgroundColor: ['#ebeef5', '#97E7C0'],
                  data: [
                    Math.round((this.total.realisasi - this.temuan.terealisasi.nilai_retribusi_keseluruhan_pad) / this.total.realisasi * 100),
                    Math.round(this.temuan.terealisasi.nilai_retribusi_keseluruhan_pad / this.total.realisasi * 100)
                  ]
                }
              ]
            }

            this.dataPieTemuan = {
              labels: ['Lainya', 'Temuan'],
              datasets: [
                {
                  backgroundColor: ['#ebeef5', '#FFCD6B'],
                  data: [
                    Math.round((this.total.potensi - this.temuan.potensi.nilai_retribusi_keseluruhan_simbg - this.temuan.potensi.usulan_retribusi) / this.total.potensi * 100),
                    Math.round(this.temuan.potensi.nilai_retribusi_keseluruhan_simbg + this.temuan.potensi.usulan_retribusi / this.total.potensi * 100)
                  ]
                }
              ]
            }

            this.tagihan.berkas_tagihan=0
            this.tagihan.total_berkas=0
            this.tagihan.total_tagihan=0
            response.data.tagihan.map(val => {
              this.tagihan.berkas_tagihan += parseInt(val.berkas_tagihan)
              this.tagihan.total_berkas += parseInt(val.total_berkas)
              this.tagihan.total_tagihan += parseInt(val.tagihan)
            })

            this.dataPieTagihan = {
              labels: ['Lainya', 'Tagihan'],
              datasets: [
                {
                  backgroundColor: ['#ebeef5', '#FF8F8A'],
                  data: [
                    Math.round((this.tagihan.total_berkas - this.tagihan.berkas_tagihan) / this.tagihan.total_berkas * 100),
                    Math.round(this.tagihan.berkas_tagihan / this.tagihan.total_berkas * 100)
                  ]
                }
              ]
            }

          })
          .catch(error => {
            console.error('There was an error!', error);
          })


      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
      }

    },
    persen_berkas(item) {
      if (item.jumlah_berkas == '0' || this.total.total_berkas == 0) {
        return 0
      }
      // if (item.status_permohonan == 'Selesai Terbit') {
      //   return Math.round(parseInt(item.nilai_retribusi_keseluruhan_pad) / this.total.potensi * 1000)/10
      // }
      return Math.round((parseInt(item.usulan_retribusi) + parseInt(item.nilai_retribusi_keseluruhan_simbg) + parseInt(item.denda)) / this.total.potensi * 1000)/10
    },
    filteredBerkas(status) {
      let filtered = this.$store.state.filter
      filtered.status_permohonan = [status]
      this.$router.push('/berkas')
    },
    tagihanBerkas() {
      let filtered = this.$store.state.filter
      filtered.status_permohonan = ['Pelunasan Retribusi', 'Penagihan Retribusi']
      filtered.pembayaran = 'belum_lunas'
      this.$router.push('/berkas')
    },
    terbitBerkas(temuan) {
      let filtered = this.$store.state.filter
      filtered.pembayaran = 'lunas'
      if (temuan) {
        filtered.temuan = 'Ya'
      }
      this.$router.push('/berkas')
    },
    potensiBerkas(temuan) {
      let filtered = this.$store.state.filter
      filtered.status_permohonan = ['Verifikasi Kelengkapan Dokumen', 'BA TPA/TPT', 'Perhitungan Retribusi', 'Validasi PTSP', 'Penagihan Retribusi', 'Pelunasan Retribusi']
      if (temuan) {
        filtered.temuan = 'Ya'
      }
      this.$router.push('/berkas')
    },
    allBerkas(){
      this.$router.push('/berkas')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textCenter {
  text-align: center;
}


.mainpage {
  border-color: #030f6b;
  border-width: 2px;
  border-style: solid;
  border-radius: 15px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}

.progress .el-progress--line {
  margin: 5px !important;
}


.progress .el-row {
  margin: 5px;
  align-items: center;
}

.proyek {
  font-size: large;
}

.total-berkas {
  margin-top: 8px;
}

.el-col {
  padding: 0;
}

.el-card__body {
  padding: 0;
}

.pad-card {
  border-color: #030F6B;
  --el-card-padding: 10px;
  margin: 10px;
}

.terbit-card {
  border-color: #359439;
  --el-card-padding: 10px;
  margin: 10px;
}

.pot-card {
  border-color: #FFCD6B;
  --el-card-padding: 10px;
  margin: 10px;
}

.per-card {
  border-color: #565656;
  --el-card-padding: 10px;
  margin: 10px;
}

.dev-card {
  border-color: #FF6F6F;
  --el-card-padding: 10px;
  margin: 10px;
}

.pie-view{
  min-height: 230px !important;
}

.statistic {
  display: flex;
  justify-content: space-between;
  padding-inline: 5px;
}

.statistic-card {
  padding: 0px;
}

.proyek-bar {
  padding: 5px;
}

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-status {
  padding-inline: 10px;
}

.detail {
  font-size: small;
  padding-inline: 5px;
}

p {
  margin: 0;
}

h1 {
  margin-bottom: 15px;
  margin-top: 0px;
}
</style>