<template>
    <FilterBoard @filter-data="filterData"></FilterBoard><br>
    <el-table :data="tableData" border :row-class-name="tableRowClassName" style="width: 100%">
        <el-table-column prop="jenis_konsultasi" label="Jenis Konsultasi"  width="120" />
        <el-table-column prop="no_registrasi" label="No. Registrasi" width="120" />
        <el-table-column prop="nama_pemilik" label="Nama Pemilik" width="180" />
        <el-table-column prop="lokasi_bg" label="Lokasi BG"  width="180" />
        <el-table-column prop="fungsi_bg" label="Fungsi BG"  width="80"/>
        <el-table-column prop="nama_bangunan" label="Nama BG"  width="120"/>
        <el-table-column prop="lb" label="Luas BG">
            <template v-slot="scope">
                {{scope.row.lb || 0}} m&sup2;
            </template>
        </el-table-column>
        <!-- <el-table-column prop="tgl_permohonan" label="Tgl. Permohonan" /> -->
        <el-table-column prop="kepemilikan" label="Kepemilikan" width="80"/>
        <el-table-column prop="status_permohonan" label="Status" width="120"/>
        <el-table-column label="Tagihan" width="130">
            <template v-slot="scope">
                {{formatRupiah(scope.row.nilai_retribusi_keseluruhan_simbg == 0 || scope.row.nilai_retribusi_keseluruhan_simbg == '0' ? scope.row.usulan_retribusi : scope.row.nilai_retribusi_keseluruhan_simbg )}}
            </template>
        </el-table-column>
        <el-table-column prop="nilai_retribusi_keseluruhan_pad" label="Terbayar" width="130">
            <template v-slot="scope">
            {{ formatRupiah(scope.row.nilai_retribusi_keseluruhan_pad) }}
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="Aksi">
            <template #default="scope">
                <el-button link type="primary" size="small" @click.prevent="viewDetail(scope)">
                    Detail
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <br />
    <div>
        <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize"
            :page-sizes="[20, 50, 100, 500]" :size="size" :disabled="disabled" :background="background"
            layout="total, sizes, prev, pager, next" :total="tableCount" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
    </div>
</template>

<script>
import axios from 'axios'
import FilterBoard from '@/components/FilterBoard.vue'

export default {
    name: 'MainMenu',
    components: {
        FilterBoard
    },
    data() {
        return {
            tableData: [],
            tableCount: 0,
            currentPage : 1,
            pageSize : 20,
        }
    },
    computed: {
        tableRowClassName() {
            return ''
        }
    },
    mounted() {
        this.fetchData()

    },
    methods: {
        filterData(){
            //this.changePath()
            this.fetchData()            
        },
        /*
        changePath(){
            let filtered = {};
            Object.entries(this.filter).forEach(([key, value]) => {
                if (value && value !== '') {
                    filtered[key] = value
                }
            });

            this.$router.replace(({
                path: '/berkas',
                query: filtered
            }));
        },
        */
        fetchData() {
            try {
                const params = {
                    limit : this.pageSize,
                    offset : this.currentPage-1,
                    tahun_permohonan :this.$store.state.filter.tahun.join(','),
                    kecamatan :this.$store.state.filter.kecamatan.join(','),
                    kepemilikan :this.$store.state.filter.kepemilikan.join(','),
                    fungsi_bg :this.$store.state.filter.fungsi.join(','),
                    potensi :this.$store.state.filter.potensi,
                    status_permohonan :this.$store.state.filter.status_permohonan.join(','),
                    temuan :this.$store.state.filter.temuan,
                    query :this.$store.state.filter.search_input,
                    pembayaran:this.$store.state.filter.pembayaran,
                    fields : "id,jenis_konsultasi,no_registrasi,nama_pemilik,fungsi_bg,nama_bangunan,kepemilikan,kategori_retribusi,temuan,lokasi_bg,tgl_permohonan,status_permohonan,lb,nilai_retribusi_keseluruhan_simbg,nilai_retribusi_keseluruhan_pad,usulan_retribusi"
                }

                axios.get(this.$store.state.base_url + "/api/pbg",
                    {
                        params,
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.auth_token}`
                        }
                    }).then(response => {
                        this.tableData = response.data.data;
                        this.tableCount = response.data.count;
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })


            } catch (error) {
                console.error('Terjadi kesalahan saat mengambil data:', error);
            }
        },
        formatRupiah(value) {
            if (!value) return 'Rp 0';
            return '' + parseInt(value, 10).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).replace(/,00$/, '');
        },
        viewDetail(row) {
            this.$router.push('/detail/' + row.row.id);
        },
        handleCurrentChange(val){
            this.currentPage=val
            this.fetchData();
        },
        handleSizeChange(val){
            this.pageSize=val
            this.fetchData();
        }
    }
}
</script>

<style>
.el-table .warning-row {
    --el-table-tr-bg-color: var(--el-color-warning-light-9);
}

.el-table .success-row {
    --el-table-tr-bg-color: var(--el-color-success-light-9);
}

.el-table {
    --el-table-header-bg-color: #8CCAEE;
    --el-table-header-text-color: #070707;
    --el-table-header-font-weight: bold;
    border-radius: 12px;
}

.el-pagination {
    justify-content: center;
    --el-color-primary: #8CCAEE;
}
</style>