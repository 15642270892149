<template>
    <div class="mainpage">
        <el-row :gutter="20">
            <el-col :span="4">
                <b>Kinerja Bulanan</b>
            </el-col>
            <el-col :span="4">
                <el-select v-model="filter.tahun" placeholder="Tahun">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-col>
            <el-col :span="12"></el-col>
            <el-col :span="4">
                Total Berkas Menunggu Verifikasi : 4
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <BarKinerjaBulanan style="width:100%;height: 400px;" />
            </el-col>

        </el-row>
    </div>
</template>

<script>
import BarKinerjaBulanan from '@/components/BarKinerjaBulanan.vue'

export default {
    name: 'KinerjaPage',
    components: {
        BarKinerjaBulanan
    },
    props: {
        msg: String
    },
    data(){
        return{
            filter: {
                    tahun: "2024",
                    kepemilikan: null,
                    fungsi: null,
                    potensi: null,
                    search_input: null
                },
                options: [
                    {
                        value: '2024',
                        label: '2024',
                    },
                    {
                        value: '2023',
                        label: '2023',
                    }
                ],
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.demo-progress .el-progress--line {
    margin-bottom: 15px;
    max-width: 600px;
}
</style>
