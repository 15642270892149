import { createStore } from 'vuex'

export default createStore({
  state: {
    base_url: process.env.VUE_APP_API_URL,
    auth_token: "",
    filter: {
      tahun: ['2024'],
      kecamatan: [],
      kepemilikan: [],
      fungsi: [],
      potensi: null,
      status_permohonan: [],
      temuan: null,
      query: null,
      pembayaran: null,
    }
  },
  getters: {
    getFilter: (state) => state.filter,
  },
  mutations: {
    setFilter(state, payload) {
      console.log('setfilter', payload)
      payload.tahun= payload.tahun ?? ['2024'],
      payload.kecamatan= payload.kecamatan ?? [],
      payload.kepemilikan= payload.kepemilikan ?? [],
      payload.fungsi= payload.fungsi ?? [],
      payload.potensi= payload.potensi ?? null,
      payload.status_permohonan= payload.status_permohonan ?? [],
      payload.temuan= payload.temuan ?? null,
      payload.query= payload.query ?? null,
      payload.pembayaran= payload.pembayaran ?? null,
      state.filter = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
