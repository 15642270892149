<template>
    <div class="password-page">
        <el-row :gutter="20">
            <el-col :sm="{ span: 12, offset: 6 }" :xs="{ span: 20, offset: 2 }">
                <h3>Ganti Password</h3>
                <p>Nama : {{ form.username }}</p>
                <p>Email : {{ form.useremail }}</p>
                <br />
                <el-form :label-position="'top'" :model="form" status-icon :rules="rules" ref="ruleForm"
                    label-width="120px" class="demo-ruleForm">

                    <el-form-item label="Password Baru" prop="password">
                        <el-input type='password' v-model="form.password" autocomplete="off" show-password></el-input>
                    </el-form-item>

                    <el-form-item label="Konfirmasi Password" prop="password2">
                        <el-input type='password' v-model="form.password2" autocomplete="off" show-password></el-input>
                    </el-form-item>
                    <el-alert v-show="response.success!='pending'" :title="response.msg" :type="response.success" />
                    <br />
                    <br />
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">Simpan</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "PasswordPage",
    data() {
        return {
            form: {
                username: "",
                useremail: "",
                userid: "",
                password: "",
                password2: ""
            },
            response: {
                msg: '',
                success:'pending'
            },
            rules: {
                password: [
                    { required: true, message: 'Please input your Password', trigger: 'blur' }
                ],
                password2: [
                    { required: true, message: 'Please input your Password', trigger: 'blur' }
                ]
            },
        }
    },
    created() {
        if(localStorage.userid==undefined){
            localStorage.removeItem('auth_token');
            this.$router.push('/masuk')
        }
        this.form.useremail = localStorage.useremail
        this.form.username = localStorage.username
        this.form.userid = localStorage.userid
    },
    computed: {

    },
    methods: {
        submitForm(formName) {
            this.response.success='pending'
            if(this.form.password!=this.form.password2){
                this.response.msg = "Ganti password gagal. konfirmasi password berbeda."
                this.response.success='error'
                return false
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const postData = {
                        name: this.form.username,
                        email: this.form.useremail,
                        password: this.form.password
                    };
                    console.log(this.form)
                    axios.put(this.$store.state.base_url + "/api/user/" + this.form.userid, postData,
                        {
                            headers: {
                                'Authorization': `Bearer ${this.$store.state.auth_token}`
                            }
                        }
                    ).then(response => {
                        console.log(response.data)
                        if (response.data.success) {
                            this.response.success='success'
                            this.response.msg = "Ganti password berhasil."
                        }
                    })
                        .catch(error => {
                            this.response.msg = "Ganti password gagal. Silahkan coba lagi."
                            this.response.success='error'
                            console.error('There was an error!', error);
                        })

                } else {
                    return false;
                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>