<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="24">
            <MainMenu v-if="showMainMenu"/>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
          <!-- <br> -->
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue'
export default {
  name: 'MainView',
  components: {
    MainMenu
  },
  computed: {
    showMainMenu() {
      return this.$route.meta.showMainMenu;
    },
  },
  created(){
    if(localStorage.auth_token){
     this.$store.state.auth_token = localStorage.auth_token
    }
  }
}
</script>

<style>
body{
  background-color: #F9FBFB;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #070707;
}

.el-header{
  padding:0;
  position: sticky;
  top:0;
  z-index: 10;
}


html,body { 
  margin: 0;
}

</style>
