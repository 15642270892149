<template>
  <h1>Berkas {{ detailData.no_registrasi }}</h1>
  <div class="statusdiv">
    <span class="status">Status: {{ detailData.status_permohonan }}</span>
  </div>
  <div class="detailpage">
    <el-row :gutter="20">
      <el-col :md="12">
        <el-descriptions title="Informasi Umum" :column="1" :size="size" border>
          <el-descriptions-item label="No. Registrasi">{{ detailData.no_registrasi }}</el-descriptions-item>
          <el-descriptions-item label="Nama Pemilik">{{ detailData.nama_pemilik }}</el-descriptions-item>
          <el-descriptions-item label="Alamat Pemilik">{{ detailData.alamat_pemilik }}</el-descriptions-item>
          <el-descriptions-item label="No. Telp/HP">{{ detailData.no_hp }}</el-descriptions-item>
          <el-descriptions-item label="Email">{{ detailData.email_pemilik }}</el-descriptions-item>
          <el-descriptions-item class="divider" label=""></el-descriptions-item>

          <el-descriptions-item label="Jenis Konsultasi">{{ detailData.jenis_konsultasi }}</el-descriptions-item>
          <el-descriptions-item label="Lokasi Bangunan">{{ detailData.lokasi_bg }}</el-descriptions-item>
          <el-descriptions-item label="Fungsi bangunan">{{ detailData.fungsi_bg }}</el-descriptions-item>
          <el-descriptions-item label="Nama Bangunan">{{ detailData.nama_bangunan }}</el-descriptions-item>
          <el-descriptions-item label="Kepemilikan">{{ detailData.kepemilikan }}</el-descriptions-item>
          <el-descriptions-item label="Luas Bangunan">{{ detailData.lb }} m&sup2;</el-descriptions-item>
          <el-descriptions-item label="Tinggi Bangunan">{{ detailData.TB }} m</el-descriptions-item>
          <el-descriptions-item label="Jumlah Lantai Bangunan">{{ detailData.JLB }} lantai</el-descriptions-item>
          <el-descriptions-item label="Latitude | Longitude">{{ detailData.latitude }} | {{ detailData.longitude }}
          </el-descriptions-item>
          <el-descriptions-item class="divider" label=""></el-descriptions-item>

          <el-descriptions-item label="Kategori Retribusi">{{ detailData.kategori_retribusi }}</el-descriptions-item>
          <el-descriptions-item v-if="detailData.nilai_retribusi_keseluruhan_simbg == 0" label="Usulan Retribusi">{{
            formatRupiah(detailData.usulan_retribusi) }}</el-descriptions-item>
          <el-descriptions-item v-else label="Nilai Retribusi">{{
            formatRupiah(detailData.nilai_retribusi_keseluruhan_simbg) }}</el-descriptions-item>
          <el-descriptions-item label="Setoran PAD">{{
            formatRupiah(detailData.nilai_retribusi_keseluruhan_pad) }}</el-descriptions-item>
          <el-descriptions-item label="Denda">{{ formatRupiah(detailData.denda) }}</el-descriptions-item>
          <el-descriptions-item label="Temuan">{{ detailData.temuan ? "Ya" : "Bukan" }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :md="12">
        <div v-if="detailData.status_permohonan == 'Dokumen Belum Lengkap'">
          <el-descriptions title="Catatan Berkas" :column="1" :size="size" border>
          </el-descriptions>
          <el-card style="" shadow="never">
            <p>{{ detailData.catatan_kekurangan_dok }}</p>
          </el-card>
          <br />
        </div>

        <el-descriptions title="Riwayat Berkas" :column="1" :size="size" border>
        </el-descriptions>
        <el-card style="" shadow="never">
          <!-- <template #header>
          <div class="card-header">
            <span>Riwayat Pengajuan</span>
          </div>
        </template> -->
          <el-row>
            <el-col :md="12">
              <el-timeline>
                <el-timeline-item v-for="(activity, index) in activities" :key="index"
                  :timestamp="formatDate(activity.timestamp) || '-'">
                  {{ activity.content }}
                </el-timeline-item>
              </el-timeline>
            </el-col>
            <el-col :md="12">

              <el-result icon="warning" :title="detailData.status_permohonan" sub-title="Status Terakhir">
                <template #extra>
                  <el-button type="primary" @click="showStatusSIMBG(detailData.no_registrasi)">Cek status di
                    SIMBG</el-button>
                </template>
              </el-result>
            </el-col>
          </el-row>


        </el-card>

        <!-- <el-descriptions title="Kemajuan Perizinan" v-show="false" :column="1" :size="size" border>
          <el-descriptions-item label="Tanggal Permohonan">{{ detailData.tgl_permohonan }}</el-descriptions-item>
          <el-descriptions-item label="Tanggal Verifikasi Operator">{{ detailData.tgl_verifikasi }}</el-descriptions-item>
          <el-descriptions-item label="Tanggal Izin Dinas Teknis">-</el-descriptions-item>
          <el-descriptions-item label="Tamggal Izin DPMPTSP">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal TTD Kepala Dinas">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal Pembayaran">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal Penerimaan PAD">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal Penerbitan Berkas">-</el-descriptions-item>
          <el-descriptions-item label="Catatan Keuangan">-</el-descriptions-item>
          <el-descriptions-item label="Informasi">-</el-descriptions-item>
        </el-descriptions> -->
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col>
        <el-descriptions title="Catatan" :column="1" :size="size" border></el-descriptions>
        <div class="block">
          <div v-for="(note, index) in notes" :key="index">
            <el-card shadow="never">
              <div class="cat-headers">
                <div class="cat-header">
                  <h4>{{ note.created_by }}</h4>
                  <span class="cat-time">{{ note.created_at.substring(0, 10) }} {{ note.created_at.substring(11, 19)
                    }}</span>
                </div>
                <div v-if=" note.created_by == user && index !== editing" class="cat-icons">
                  <el-button @click="editing = index">
                    <el-icon>
                      <edit />
                    </el-icon>
                  </el-button>
                  <el-button @click="delete_notes(index)">
                    <el-icon>
                      <delete />
                    </el-icon>
                  </el-button>
                </div>
              </div>
              <div v-if="index !== editing" >
                <div  class="cats">
                  <div v-for="cat in (note.categories ? note.categories.split(',') : [])" :key="cat" class="category">
                    {{ cat }}
                  </div>
                </div>
                <p>{{ note.note }}</p>
                <p v-if="note.file_url" @click="showFiles(note)"><br>
                  <el-check-tag type="primary">{{ note.file_names }}</el-check-tag>
                </p>
              </div>
              <div v-if="index == editing">
                <el-input v-model="note.note" style="" :rows="3" type="textarea" placeholder="Ketik catatan disini" />
                <br>
                <div v-if="errors.note" class="error-message">{{ errors.note }}</div>
                <br>
                <el-select v-model="note.categories" multiple filterable placeholder="Kategori">
                  <el-option v-for="item in categories" :key="item.lov_value" :label="item.lov_label"
                    :value="item.lov_value" />
                </el-select>
                <br>
                <br>
                <el-upload ref="upload" class="upload-demo" :action="this.$store.state.base_url + '/api/files/upload'"
                  :limit="1" :on-exceed="handleExceed" :on-success="handleEditSuccess" :auto-upload="true" :fileList="note.file_names ? [
                    {
                      name: note.file_names,
                      url: note.file_url,
                      status: 'finished', // Indicates the file is already uploaded
                      uid: -1 // Unique identifier for internal management
                    }
                  ] : []">
                  <template #trigger>
                    <el-button>Pilih file </el-button>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      File yang diijinkan gambar dan PDF.
                    </div>
                  </template>
                </el-upload>
                <br />
                <el-button type="primary" @click="edit_notes(index)">Simpan</el-button>
              </div>

            </el-card><br>
          </div>
        </div>
        <el-card shadow="never">
          <template #header>
            <div class="card-header">
              <span>Tambah Catatan</span>
            </div>
          </template>
          <div>
            <el-input v-model="form.note" style="" :rows="3" type="textarea" placeholder="Ketik catatan disini" />
            <br>
            <div v-if="errors.note" class="error-message">{{ errors.note }}</div>
            <br>
            <el-select v-model="form.categories" multiple filterable placeholder="Kategori">
              <el-option v-for="item in categories" :key="item.lov_value" :label="item.lov_label"
                :value="item.lov_value" />
            </el-select>
            <br>
            <br>
            <el-upload ref="upload" class="upload-demo" :action="this.$store.state.base_url + '/api/files/upload'"
              :limit="1" :on-exceed="handleExceed" :on-success="handleSuccess" :auto-upload="true">
              <template #trigger>
                <el-button>Pilih file </el-button>
              </template>
              <template #tip>
                <div class="el-upload__tip">
                  File yang diijinkan gambar dan PDF.
                </div>
              </template>
            </el-upload>
          </div>
          <template #footer>
            <el-button type="primary" @click="save_notes">Simpan</el-button>
          </template>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog v-model="showLacakBerkasSIMBG" title="LACAK BERKAS SIMBG" width="800">
      <h5>No. Registrasi {{ detailData.no_registrasi }}</h5><br>
      <div v-html="detailSIMBG">
      </div>
    </el-dialog>

    <el-dialog v-model="showFilesDialog" title="Lampiran" width="600">
      <div>
        <el-image v-if="selectedNote.file_type != 'application/pdf'" :src="urlFiles" fit="fill" />
        <iframe v-if="selectedNote.file_type == 'application/pdf'" id="fred" style="border:1px solid #666CCC"
          title="Lampiran" :src="urlFiles" frameborder="0" scrolling="auto" width="100%" height="600px"></iframe>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import { Delete, Edit } from '@element-plus/icons-vue';


export default {
  name: 'MainMenu',
  components: {
    Delete,
    Edit
  },
  data() {
    return {
      images: ["Pengajuan awal", "Lapangan"
      ],
      activities: [],
      detailData: {},
      detailSIMBG: "",
      showLacakBerkasSIMBG: false,
      showFilesDialog: false,
      form: {
        note: "",
        categories: ""
      },
      errors: {
        note: ""
      },
      notes: [],
      selectedNote: {},
      editing: null,
      categories: "",
      user: localStorage.username
    }
  },
  created() {
    this.fetchData();
    this.fetchNotesData();
    this.fetchCategories();
    this.form.spf_record_id = this.$route.params.id;
  },
  methods: {
    formatRupiah(value) {
      if (!value) return 'Rp 0';
      return '' + parseInt(value, 10).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).replace(/,00$/, '');
    },
    formatDate(value) {
      if (!value) return '-';
      return value.toString().split(' ')[0];
    },
    fetchCategories() {
      try {
        const params = {
          lov_id: 'kategori_temuan'
        }
        axios.get(this.$store.state.base_url + "/api/lov",
          {
            params,
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            const sortedData = response.data.data.sort((a, b) => a.lov_order - b.lov_order);
            this.categories = sortedData;
          })
          .catch(error => {
            console.error('There was an error!', error);
          })


      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
      }
    },
    fetchData() {
      try {
        axios.get(this.$store.state.base_url + "/api/pbg/" + this.$route.params.id,
          {
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            this.detailData = response.data.data;
            this.activities = [{
              content: 'Pengajuan Permohonan',
              timestamp: this.detailData.tgl_permohonan,
            },
            {
              content: 'Verifikasi Kelengkapan Dokumen',
              timestamp: this.detailData.tgl_verifikasi,
            },
            ...(this.detailData.no_urut_ba_tpa !== null && this.detailData.no_urut_ba_tpa !== '' ? [{ content: 'Verifikasi BA TPA', timestamp: this.detailData.tgl_ba_tpa }] : [{ content: 'Verifikasi BA TPT', timestamp: this.detailData.tgl_ba_tpt }]),
            {
              content: 'Perhitungan Retribusi & Penerbitan SKRD',
              timestamp: this.detailData.tgl_skrd,
            },
            {
              content: 'Penerimaan Retribusi',
              timestamp: this.detailData.tgl_sts,
            },
            ...(this.detailData.is_deleted ? [{ content: 'Berkas Dihapus', timestamp: this.detailData.tgl_dihapus }] : []),
            ]
          })
          .catch(error => {
            console.error('There was an error!', error);
          })


      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
      }
    },
    fetchNotesData() {
      try {
        axios.get(this.$store.state.base_url + "/api/notes/" + this.$route.params.id,
          {
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            this.notes = response.data.data;
          })
          .catch(error => {
            console.error('There was an error!', error);
          })


      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
      }
    },
    showStatusSIMBG(no_reg) {
      this.showLacakBerkasSIMBG = true
      try {
        axios.get("https://simbgv2.pu.go.id/Informasi/Lacak/" + no_reg)
          .then(response => {
            this.detailSIMBG = '<table class="tbl_simbg"><thead><tr><th width="4%">#</th><th>Modul</th><th width="12%">Tanggal</th><th>Keterangan</th></tr></thead>' + response.data + "</table>";
          })
          .catch(error => {
            console.error('There was an error!', error);
          })


      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
      }
    },
    handleSuccess(val) {
      this.form.spf_file_id = val.data.id
    },
    handleEditSuccess(val) {
      this.notes[this.editing].spf_file_id = val.data.id
    },
    showFiles(note) {
      this.showFilesDialog = true
      this.urlFiles = this.$store.state.base_url + "/api/files/view/" + note.file_url
      this.selectedNote = note
    },
    save_notes() {
      this.errors.note = '';
      if (!this.form.note) {
        this.errors.note = 'Catatan harus diisi.'; // Set error message
        return false;
      }
      try {
        console.log(this.form.categories)
        const payload = {
          note: this.form.note,
          spf_file_id: this.form.spf_file_id,
          categories: this.form.categories.join(','),
          spf_record_id: this.form.spf_record_id
        }
        console.log(payload)
        axios.post(this.$store.state.base_url + "/api/note",
          payload,
          {
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            console.log(response)
            this.fetchNotesData()
            this.form.note = ''
            this.form.spf_file_id = ''
            this.form.categories = []
            this.$refs.upload.clearFiles();
          })
          .catch(error => {
            console.error('There was an error!', error);
          })


      } catch (error) {
        console.error('Terjadi kesalahan saat mengambil data:', error);
      }
    },
    edit_notes(index) {
      const edited = this.notes[index];
      console.log(edited)

      this.errors.note = '';
      if (!edited.note) {
        this.errors.note = 'Catatan harus diisi.'; // Set error message
        return false;
      }
      console.log(edited.categories)
      const payload = {
        note: edited.note,
        spf_file_id: edited.spf_file_id,
        categories: edited.categories.join(','),
      }
      console.log(payload)

      try {
        axios.put(this.$store.state.base_url + `/api/note/${edited.id}`,
          payload,
          {
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            console.log(response)
            this.editing = null
            this.fetchNotesData()
            edited.note = ''
            edited.spf_file_id = ''
            edited.categories = []
            this.$refs.upload.clearFiles();
          })
          .catch(error => {
            console.error('There was an error!', error);
          })

      } catch (error) {
        console.error('Terjadi kesalahan saat menghapus data:', error);
      }
    },
    delete_notes(index) {
      const noteId = this.notes[index].id;
      try {
        axios.delete(this.$store.state.base_url + `/api/note/${noteId}`,
          {
            headers: {
              'Authorization': `Bearer ${this.$store.state.auth_token}`
            }
          }).then(response => {
            console.log(response)
          })
          .catch(error => {
            console.error('There was an error!', error);
          })

      } catch (error) {
        console.error('Terjadi kesalahan saat menghapus data:', error);
      }
    }
  }
}
</script>

<style>
h1 {
  text-align: center;
  color: #030f6b;
  margin-bottom: 5px;
}

.detailpage {
  border-color: #030f6b;
  border-width: 2px;
  border-style: solid;
  border-radius: 15px;
  padding: 24px;
}

h4 {
  margin-top: 0px;
  margin-bottom: 8px;
}

p {
  margin: 0px;
}

.statusdiv {
  display: flex;
  justify-content: center;
}

.status {
  color: #606266;
  text-align: center;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  background-color: #FFD584;
}

.tbl_simbg {
  border: 1px solid #ddd;
  border-spacing: 0px;
  /* Add spacing between borders */
}

.tbl_simbg td,
.tbl_simbg th {
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0px;
  padding: 2px 10px 2px 10px;
  margin: 0px;
}

.tbl_simbg tr {
  margin: 0px;
  border-spacing: 0px;
  border-collapse: collapse;
}

.cat-headers {
  display: flex;
  justify-content: space-between;
}

.cat-icons {
  display: flex;
  align-items: baseline;
}

.cat-header {
  display: flex;
  gap: 20px;
  align-items: baseline;
}

.cat-time {
  font-size: small;
}

.cats {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 5px;
}

.category {
  font-size: small;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #FFCD6B;
}

.error-message {
  color: #FF6F6F;
  font-size: small;
}
</style>
