<template>
    <div style="text-align:center"><img style="width: 100px" src="@/assets/logo.png" alt="DPUTR Kab. Bandung" /></div>
    <div class="formLacak" v-if="!detailData.no_registrasi">
        <b>Masukan No Registrasi PBG: </b><br>
        <el-input v-model="no_registrasi" style="width: 350px" placeholder="No. Registrasi PBG" />
        <el-button type="primary" @click="fetchData()">Lacak</el-button>
        <br />
        <br />
        <el-text class="mx-1" type="warning" v-loading="loading">{{ not_found_message }}</el-text>
    </div>


    <div class="detailpage" v-if="detailData.no_registrasi">
        <div class="statusdiv">
            <h1>No PBG: {{ detailData.no_registrasi }}</h1>
        </div>

        <div class="statusdiv">
            <h2 class="status">Status: {{ detailData.status_permohonan }}</h2>
        </div>

        <el-row :gutter="20">
            <el-col :md="12">
                <el-descriptions title="Informasi Umum" :column="1" :size="size" border>
                    <el-descriptions-item label="No. Registrasi">{{ detailData.no_registrasi }}</el-descriptions-item>
                    <el-descriptions-item label="Nama Pemilik">{{ detailData.nama_pemilik }}</el-descriptions-item>
                    <el-descriptions-item label="Alamat Pemilik">{{ detailData.alamat_pemilik }}</el-descriptions-item>
                    <el-descriptions-item label="No. Telp/HP">{{ detailData.no_hp }}</el-descriptions-item>
                    <el-descriptions-item label="Email">{{ detailData.email_pemilik }}</el-descriptions-item>
                    <el-descriptions-item class="divider" label=""></el-descriptions-item>

                    <el-descriptions-item label="Jenis Konsultasi">{{ detailData.jenis_konsultasi
                        }}</el-descriptions-item>
                    <el-descriptions-item label="Lokasi Bangunan">{{ detailData.lokasi_bg }}</el-descriptions-item>
                    <el-descriptions-item label="Fungsi bangunan">{{ detailData.fungsi_bg }}</el-descriptions-item>
                    <el-descriptions-item label="Nama Bangunan">{{ detailData.nama_bangunan }}</el-descriptions-item>
                    <el-descriptions-item label="Kepemilikan">{{ detailData.kepemilikan }}</el-descriptions-item>
                    <el-descriptions-item label="Luas Bangunan">{{ detailData.lb }} m&sup2;</el-descriptions-item>
                    <el-descriptions-item label="Tinggi Bangunan">{{ detailData.TB }} m</el-descriptions-item>
                    <el-descriptions-item label="Jumlah Lantai Bangunan">{{ detailData.JLB }}
                        lantai</el-descriptions-item>
                    <el-descriptions-item label="Latitude | Longitude">{{ detailData.latitude }} | {{
                        detailData.longitude }}
                    </el-descriptions-item>
                    <el-descriptions-item class="divider" label=""></el-descriptions-item>

                    <el-descriptions-item label="Kategori Retribusi">{{ detailData.kategori_retribusi
                        }}</el-descriptions-item>
                    <el-descriptions-item v-if="detailData.nilai_retribusi_keseluruhan_simbg == 0"
                        label="Usulan Retribusi">{{
                            formatRupiah(detailData.usulan_retribusi) }}</el-descriptions-item>
                    <el-descriptions-item v-else label="Nilai Retribusi">{{
                        formatRupiah(detailData.nilai_retribusi_keseluruhan_simbg) }}</el-descriptions-item>
                    <el-descriptions-item label="Setoran PAD">{{
                        formatRupiah(detailData.nilai_retribusi_keseluruhan_pad) }}</el-descriptions-item>
                    <el-descriptions-item label="Denda">{{ formatRupiah(detailData.denda) }}</el-descriptions-item>
                    <el-descriptions-item label="Temuan">{{ detailData.temuan ? "Ya" : "Bukan" }}</el-descriptions-item>
                </el-descriptions>
            </el-col>
            <el-col :md="12">
                <div v-if="detailData.status_permohonan == 'Dokumen Belum Lengkap'">
                    <el-descriptions title="Catatan Berkas" :column="1" :size="size" border>
                    </el-descriptions>
                    <el-card style="" shadow="never">
                        <p>{{ detailData.catatan_kekurangan_dok }}</p>
                    </el-card>
                    <br />
                </div>

                <el-descriptions title="Riwayat Berkas" :column="1" :size="size" border>
                </el-descriptions>
                <el-card style="" shadow="never">
                    <!-- <template #header>
          <div class="card-header">
            <span>Riwayat Pengajuan</span>
          </div>
        </template> -->
                    <el-row>
                        <el-col :md="12">
                            <el-timeline>
                                <el-timeline-item v-for="(activity, index) in activities" :key="index"
                                    :timestamp="formatDate(activity.timestamp) || '-'">
                                    {{ activity.content }}
                                </el-timeline-item>
                            </el-timeline>
                        </el-col>
                        <el-col :md="12">

                            <el-result icon="warning" :title="detailData.status_permohonan" sub-title="Status Terakhir">
                                <template #extra>
                                    <el-button type="primary" @click="showStatusSIMBG(detailData.no_registrasi)">Lacak
                                        di SIMBG</el-button>
                                </template>
                            </el-result>
                        </el-col>
                    </el-row>


                </el-card>

                <!-- <el-descriptions title="Kemajuan Perizinan" v-show="false" :column="1" :size="size" border>
          <el-descriptions-item label="Tanggal Permohonan">{{ detailData.tgl_permohonan }}</el-descriptions-item>
          <el-descriptions-item label="Tanggal Verifikasi Operator">{{ detailData.tgl_verifikasi }}</el-descriptions-item>
          <el-descriptions-item label="Tanggal Izin Dinas Teknis">-</el-descriptions-item>
          <el-descriptions-item label="Tamggal Izin DPMPTSP">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal TTD Kepala Dinas">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal Pembayaran">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal Penerimaan PAD">-</el-descriptions-item>
          <el-descriptions-item label="Tanggal Penerbitan Berkas">-</el-descriptions-item>
          <el-descriptions-item label="Catatan Keuangan">-</el-descriptions-item>
          <el-descriptions-item label="Informasi">-</el-descriptions-item>
        </el-descriptions> -->
            </el-col>
        </el-row>
        <br />
        <el-row>
            <el-col>
                <el-descriptions title="Catatan" :column="1" :size="size" border></el-descriptions>
                <div class="block">
                    <div v-for="(note, index) in notes" :key="index">
                        <el-card shadow="never">
                            <div class="cat-headers">
                                <div class="cat-header">
                                    <h4>{{ note.created_by }}</h4>
                                    <span class="cat-time">{{ note.created_at.substring(0, 10) }} {{
                                        note.created_at.substring(11, 19)
                                        }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="cats">
                                    <div v-for="cat in (note.categories ? note.categories.split(',') : [])" :key="cat"
                                        class="category">
                                        {{ cat }}
                                    </div>
                                </div>
                                <p>{{ note.note }}</p>
                                <p v-if="note.file_url" @click="showFiles(note)"><br>
                                    <el-check-tag type="primary">{{ note.file_names }}</el-check-tag>
                                </p>
                            </div>

                        </el-card><br>
                    </div>
                </div>

            </el-col>
        </el-row>

        <el-dialog v-model="showLacakBerkasSIMBG" title="Berkas PBG di simbg.pu.go.id" width="800">
            <h5>No. Registrasi {{ detailData.no_registrasi }}</h5><br>
            <div v-html="detailSIMBG">
            </div>
        </el-dialog>

        <el-dialog v-model="showFilesDialog" title="Lampiran" width="600">
            <div>
                <el-image v-if="selectedNote.file_type != 'application/pdf'" :src="urlFiles" fit="fill" />
                <iframe v-if="selectedNote.file_type == 'application/pdf'" id="fred" style="border:1px solid #666CCC"
                    title="Lampiran" :src="urlFiles" frameborder="0" scrolling="auto" width="100%"
                    height="600px"></iframe>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'PublicDetailView',
    components: {
    },
    data() {
        return {
            images: ["Pengajuan awal", "Lapangan"
            ],
            activities: [],
            detailData: {},
            detailSIMBG: "",
            showLacakBerkasSIMBG: false,
            showFilesDialog: false,
            errors: {
                note: ""
            },
            notes: [],
            selectedNote: {},
            loading: false,
            categories: "",
            no_registrasi: "",
            not_found_message: ""
        }
    },
    created() {
        if (this.$route.params.no_registrasi) {
            this.no_registrasi = this.$route.params.no_registrasi;
            this.fetchData();
        }
    },
    methods: {
        formatRupiah(value) {
            if (!value) return 'Rp 0';
            return '' + parseInt(value, 10).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).replace(/,00$/, '');
        },
        formatDate(value) {
            if (!value) return '-';
            return value.toString().split(' ')[0];
        },
        fetchData() {
            try {
                this.loading = true
                axios.get(this.$store.state.base_url + "/api/pbg/lacak/" + this.no_registrasi,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.auth_token}`
                        }
                    }).then(response => {
                        this.loading = false
                        if (response.data.success) {
                            this.detailData = response.data.data;
                            this.notes = response.data.notes;
                            this.activities = [{
                                content: 'Pengajuan Permohonan',
                                timestamp: this.detailData.tgl_permohonan,
                            },
                            {
                                content: 'Verifikasi Kelengkapan Dokumen',
                                timestamp: this.detailData.tgl_verifikasi,
                            },
                            ...(this.detailData.no_urut_ba_tpa !== null && this.detailData.no_urut_ba_tpa !== '' ? [{ content: 'Verifikasi BA TPA', timestamp: this.detailData.tgl_ba_tpa }] : [{ content: 'Verifikasi BA TPT', timestamp: this.detailData.tgl_ba_tpt }]),
                            {
                                content: 'Perhitungan Retribusi & Penerbitan SKRD',
                                timestamp: this.detailData.tgl_skrd,
                            },
                            {
                                content: 'Penerimaan Retribusi',
                                timestamp: this.detailData.tgl_sts,
                            },
                            ...(this.detailData.is_deleted ? [{ content: 'Berkas Dihapus', timestamp: this.detailData.tgl_dihapus }] : []),
                            ]
                        } else {
                            this.not_found_message = "Data tidak ditemukan."
                        }

                    })
                    .catch(error => {
                        this.loading = false
                        this.not_found_message = "Terdapat gangguan saat mencari, silahkan hubungi admin secara langsung."
                        console.error('There was an error!', error);
                    })


            } catch (error) {
                this.loading = false
                this.not_found_message = "Terdapat gangguan saat mencari, silahkan hubungi admin secara langsung."
                console.error('Terjadi kesalahan saat mengambil data:', error);
            }
        },
        showStatusSIMBG(no_reg) {
            this.showLacakBerkasSIMBG = true
            try {
                axios.get("https://simbgv2.pu.go.id/Informasi/Lacak/" + no_reg)
                    .then(response => {
                        this.detailSIMBG = '<table class="tbl_simbg"><thead><tr><th width="4%">#</th><th>Modul</th><th width="12%">Tanggal</th><th>Keterangan</th></tr></thead>' + response.data + "</table>";
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })


            } catch (error) {
                console.error('Terjadi kesalahan saat mengambil data:', error);
            }
        },
        showFiles(note) {
            this.showFilesDialog = true
            this.urlFiles = this.$store.state.base_url + "/api/files/view/" + note.file_url
            this.selectedNote = note
        },
    }
}
</script>

<style>
h1 {
    text-align: center;
    color: #030f6b;
    margin-bottom: 5px;
}

.detailpage {
    border-color: #030f6b;
    border-width: 2px;
    border-style: solid;
    border-radius: 15px;
    padding: 24px;
}

h4 {
    margin-top: 0px;
    margin-bottom: 8px;
}

p {
    margin: 0px;
}

.statusdiv {
    justify-content: center;
}

.status {
    color: #606266;
    text-align: center;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #FFD584;
}

.tbl_simbg {
    border: 1px solid #ddd;
    border-spacing: 0px;
    /* Add spacing between borders */
}

.tbl_simbg td,
.tbl_simbg th {
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-spacing: 0px;
    padding: 2px 10px 2px 10px;
    margin: 0px;
}

.tbl_simbg tr {
    margin: 0px;
    border-spacing: 0px;
    border-collapse: collapse;
}

.cat-headers {
    display: flex;
    justify-content: space-between;
}

.cat-icons {
    display: flex;
    align-items: baseline;
}

.cat-header {
    display: flex;
    gap: 20px;
    align-items: baseline;
}

.cat-time {
    font-size: small;
}

.cats {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 5px;
}

.category {
    font-size: small;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #FFCD6B;
}

.error-message {
    color: #FF6F6F;
    font-size: small;
}

.formLacak {
    text-align: center;
}
</style>
